import { DateTime } from 'luxon'
import LibraryFunctions from './_library_functions'
import 'jquery-contextmenu'
import tachoCalendarIcons from "./../shared/tacho_calendar_icons";
import {AjaxSync} from "../shared/ajax_utilities";
import {dateConvert} from "../shared/text_utilities";
import {displayErrors, manifest} from "../shared/common";

export default class CalendarTab extends LibraryFunctions {
    // public
    spinner = `<div class="loading-spinner"><img src="${manifest('img/loading-black.svg')}"></div>`;

    // protected (convention)
    _$hostingTab;
    _upperLimit;
    _lowerLimit;

    // private (enforced)
    #$loading = $(`<tr class="loading"><td colspan="50" class="ac"><img src="${manifest('img/loading-black.svg')}" width="30"></td></tr>`);
    isEditing = false;
    isMouseDown = false;

    constructor($tab) {
        super();
        this._$hostingTab = $tab;
        $tab.find('table.loading').append(this.spinner);

        this._upperLimit = DateTime.fromSQL($('input#latest_data_raw').val()).plus({years: 1});
        this._lowerLimit = DateTime.fromSQL($('input#earliest_data_raw').val()).set({day: 1});

        this.connectHandlers();
    }

    fetchData() {}

    renderCalendarIcons($el, rows, iconProperty)
    {
        const settings = tachoCalendarIcons[iconProperty];
        const icon = `<span class="icon ${iconProperty}" title="${settings.name} #XXX (DATE)" ${settings.href ? 'data-href="' + settings.href + 'XXX"' : ''}>
            ${settings.icon}
        </span>`;
        let html = '';

        $.each(rows, function(k,row){
            html += icon.replace(/XXX/g, row.id).replace('DATE', row.date);
        });

        const $container = $el.closest('.icons');
        $container.find(`span.${iconProperty}`).filter((i, e) => {
              return e.title.match(/#\d+/)
        }).remove();
        $el.after(html);

        return $container.find(`.${iconProperty}`).on('click', (e) => {

            e.preventDefault();
            e.stopPropagation();
            if(!$(e.currentTarget).attr('data-href')) {
                return
            }

            window.location = $(e.currentTarget).attr('data-href');
        });
    }


    connectHandlers()
    {
        this._$hostingTab.find('select#month_selector,select#year_selector').change(this.rebuildTables.bind(this));
        this._$hostingTab.find('a.calendar_next_month').click(this.nextMonth.bind(this));
        this._$hostingTab.find('a.calendar_prev_month').click(this.previousMonth.bind(this));

        // not linked to user/vehicle in system do not continue
        if( !app.OPTIONS.linked_id || app.CACHE.USER.user_type_id <= app.CACHE.USER_TYPES.RESELLER ) {
            return;
        }

        const $currentMonth = this._$hostingTab.find('input#base_date');

        if( $currentMonth.length ) {
            // add link to planner
            const currentMonth = $currentMonth.val().substr(0,7);
            const $h3 = this._$hostingTab.find('h3').first();
            const tbl = ( app.URI[1] === 'tco_drv' ) ? 'usr' : 'veh';
            const plannerSlug = `${app.CACHE.URL_ADMIN_PLANNER}${tbl}/filter/tacho/control/dropdown/${currentMonth}/${app.OPTIONS.linked_id}`;

            // remove any existing
            $h3.find('span.buttons').remove();
            // add new link
            $h3.append(`<span class="buttons"><a href="${plannerSlug}" class="button button-green"><i class="fad fa-calendar"></i> View in Planner</a></span>`);
        }
    }
    previousMonth()
    {
        let baseDate = DateTime.fromISO(this._$hostingTab.find('input#base_date').val());
        const newDate =  baseDate.minus({months: 1});
        if (newDate >= this._lowerLimit) {
            this.ensureDateIsOnCalendar(newDate)
            this._$hostingTab.find('table .tacho_calendar_controls').append(this.spinner);
            this.rebuild_calendar(newDate.toISODate('YYYY-MM-DD'));
        }
    }
    nextMonth()
    {
        let baseDate = DateTime.fromISO(this._$hostingTab.find('input#base_date').val());
        const newDate =  baseDate.plus({months: 1});
        if (newDate <= this._upperLimit) {
            this.ensureDateIsOnCalendar(newDate)
            this._$hostingTab.find('table .tacho_calendar_controls').append(this.spinner);
            this.rebuild_calendar(newDate.toISODate('YYYY-MM-DD'));
        }
    }
    rebuildTables() {

        const $year = this._$hostingTab.find('select#year_selector');
        const $month = this._$hostingTab.find('select#month_selector');

        if( !$year.length ){
            console.warn('Cannot fetch tab data, no year or month values');
            return;
        }

        let baseDate = DateTime.fromJSDate(new Date(
            $year.val(),
            $month.val(),
            1
        ));

        this.ensureDateIsOnCalendar(baseDate);

        this.rebuild_calendar(baseDate.toISODate());
        this._$hostingTab.find('table.loading').append(this.spinner);
    }

    ensureDateIsOnCalendar(date) {
        if (app.URI.length == 6) {
            app.URI[5] = date.toFormat('dd-MM-yyyy')
        } else {
            app.URI.push(date.toFormat('dd-MM-yyyy'))
        }
        window.history.replaceState(null, null, '/' + app.URI.join('/'))
    }

    updateUrlFromClickedTab($activityTab, startDate, endDate) {
        const dateFilterValue = this.getNewDateFilterValue(startDate, endDate);
        const clickedTab = app.FORM.getTabKey($activityTab);
        if (app.URI[4] !== clickedTab) {
            app.URI[4] = clickedTab;
        }
        if (app.URI.length >= 5) {
            app.URI[5] = dateFilterValue;

            window.history.replaceState(null, null, '/' + app.URI.join('/'));
        }
    }

    getNewDateFilterValue(startDate, endDate) {
        if (startDate === endDate) {
            return startDate.format('DD-MM-YYYY');
        }

        return `${startDate.format('DD-MM-YYYY')}:${endDate.format('DD-MM-YYYY')}`;
    }

    activateTab(tabName)
    {
        const that = this;

        return function (e) {
            const tabClass = `.tab-${tabName}`;
            const $tab = app.DOM.tabs.filter(tabClass);
            const $tabButton = app.DOM.form_tab_a.filter(tabClass);
            let $clickedCell = $(e.target);
            if ($clickedCell.prop('tagName') !== 'td') {
                $clickedCell = $clickedCell.parents('td');
            }
            const targetDate = $clickedCell.attr('id');
            let date, endDate, startDate;

            if (targetDate.length === 8) {
                // Single day clicked
                date = moment(targetDate, 'YYYYMMDD');
                startDate = endDate = date;
            } else if (targetDate.length === 7) {
                // Weekly summary clicked
                date = moment(targetDate, 'YYYY[W]WW')
                startDate = moment(date).isoWeekday(1);
                endDate = moment(date).isoWeekday(7);
            }

            $tab.find('.startDatePicker').datepicker('setDate', startDate.toDate());
            $tab.find('.endDatePicker').datepicker('setDate', endDate.toDate());
            $tab.find('.startDatePicker').val(startDate.format('DD-MM-YYYY'));
            $tab.find('.endDatePicker').val(endDate.format('DD-MM-YYYY'));
            $tab.find('#date_start').val(startDate.format('YYYY-MM-DD'));
            $tab.find('#date_end').val(endDate.format('YYYY-MM-DD'));
            that.triggerRemoteRebuildTables(tabName);
            $tabButton.click();

            that.updateUrlFromClickedTab($tab, startDate, endDate);

            e.stopPropagation();
        }
    }

    triggerRemoteRebuildTables(tabName)
    {
        window.$tabs.get(tabName)?.rebuildTables();
    }

    _format_duration(minutes) {
        const hours = Number.parseInt(minutes / 60);
        minutes = (minutes % 60).toFixed(0);
        return `${hours}h ${minutes}m`;
    }

    _addonElements($table, parameters)
    {
        const plannerEventsURL = app.CACHE.URL_AJAX + 'tacho_drv/get/planner_events/' + parameters;
        AjaxSync({
            url: plannerEventsURL,
            method: 'GET',
            dataType: 'json',
        }, { done: (content) => this.renderPlannerEvents($table, content) });

        const commentDatesURL = app.CACHE.URL_AJAX + 'tacho_drv/get/comment_dates/' + parameters;
        AjaxSync({
            url: commentDatesURL,
            method: 'GET',
            dataType: 'json',
        }, { done: (content) => this.renderComments($table, content.data) });
    }

    renderPlannerEvents($table, content) {
        $table.find('td.has-planner-events > span.sick').remove();
        $table.find('td.has-planner-events > span.holiday').remove();
        $table.find('td.has-planner-events > span.unpaid').remove();
        $table.find('td.has-planner-events > span.not-working-here').remove();

        for (const dateStr in content.data) {
            const date = DateTime.fromISO(dateStr);
            for (const eventIndex in content.data[dateStr]) {
                const event = content.data[dateStr][eventIndex];

                const $cell = $table.find('td#' + date.toFormat('yyyyMMdd'));
                let cssClass = '', eventText = '';
                switch (event.type) {
                    case 6:
                        cssClass = 'sick';
                        eventText = 'Sickness/Absence'
                        break;
                    case 4:
                        cssClass = 'holiday';
                        eventText = 'Holiday';
                        break;
                    case 29:
                        cssClass = 'unpaid';
                        eventText = 'Unpaid/Rest (0 hours)';
                        break;
                    case 31:
                        cssClass = 'not-working-here';
                        eventText = 'Working Elsewhere';
                        $cell.data('working-elsewhere', event.id);
                        $cell.attr('title', 'Click to edit this entry');
                        break;
                    case 33:
                        cssClass = 'training';
                        eventText = 'Training';
                        $cell.data('training-day', event.id);
                        break;
                }

                console.log(event)
                if(event.subject && event.subject.trim().length > 0 && (!event.note || event.note.trim().length === 0)) {
                    event.note = event.subject;
                }

                const $span = $('<span>').addClass(cssClass).prop({
                    'title': `${event.note && event.note.length > 0 ? event.note + '(' + event.duration + ')' : event.duration}`,
                }).data({
                    'id': event.id
                }).text(eventText);

                if(event.note && event.note.length > 0) {
                    $span.prop({
                        note:event.note,
                    });
                }

                $cell.find('span.date').after($span);

                $cell.closest('td').addClass('has-planner-events');
            }
        }
    }

    workingElsewhereAddDelete(type, $td) {
        const notWorkingHere = $td.find('.not-working-here');
        const day = dateConvert(this.getDateFromCellId($td));
        const url =  `${app.CACHE.URL_AJAX}tacho_drv/get/suppression/day:${day}|driver_id:${app.URI[2]}`;
        const ajaxOpts = {
            url: url,
            method: 'GET',
        };

        let dayBeforeNote, dayAfterNote;
        const currentDay = DateTime.fromISO(day);
        const dayBeforeEl = $(`#${currentDay.minus({ days: 1 }).toISODate().replaceAll('-', '')} span.not-working-here`);
        const dayAfterEl = $(`#${currentDay.plus({ days: 1 }).toISODate().replaceAll('-', '')} span.not-working-here`);
        if(dayBeforeEl && dayBeforeEl.length > 0) {
            dayBeforeNote = dayBeforeEl.prop('note');
        }

        if(dayAfterEl && dayAfterEl.length > 0) {
            dayAfterNote = dayAfterEl.prop('note');
        }

        if(!notWorkingHere || notWorkingHere.length === 0) {
            let note;
            if(dayBeforeNote && dayBeforeNote.length > 0) {
                note = prompt(`Please enter a Driver Suppression Reason\nThe current note that will be used is from the day before "${dayBeforeNote}".`, dayBeforeNote);
            } else if(dayAfterNote && dayAfterNote.length > 0) {
                note = prompt(`Please enter a Driver Suppression Reason\nThe current note that will be used is from the day after "${dayAfterNote}".`, dayAfterNote);
            } else {
                note = prompt('Please enter a Driver Suppression Reason');
            }

            if(!note || note.length === 0) {
                return;
            }

            ajaxOpts.method = 'POST';
            ajaxOpts.data = { note: note };
        }

        AjaxSync(ajaxOpts, {
            done: (res) => {
                if(res.status !== 'success') {
                    displayErrors(res);
                    return;
                }

                this.rebuildTables();
            }
        });
    }

    ajaxAddDeleteEvent(type, $td, days, mode, $table)
    {
        const type_id = app.OPTIONS.event_types[type];
        let url =  `${app.CACHE.URL_AJAX}tacho_drv/get/event_crud/`;
        let day;
        if(days) {
            days = days.map((d) => dateConvert(`${d}`.replace(/(\d{4})(\d{2})(\d{2})/, '$3-$2-$1')));
            url += `days:${days.join(',')}`;
        } else {
            day = dateConvert(this.getDateFromCellId($td));
            url += `day:${day}`;
        }

        url += `|driver_id:${app.URI[2]}|type_id:${type_id}`;

        let ajaxOpts = {
            method: 'GET',
            dataType: 'json'
        };

        if(type === 'training') {
            if (($td && $($td).find('.training').length === 0) || (mode && mode !== 'delete')) {
                const subject = prompt('Please provide the training course name');
                if (!subject) {
                    if (this.isEditing && $table) {
                        $table.find('.bulk-edit').trigger('click');
                    }
                    return;
                }

                ajaxOpts.method = 'POST';
                ajaxOpts.data = {subject};
            } else {
                mode = 'delete';
            }
        }

        if(mode) {
            url += `|mode:${mode}`;
        }

        ajaxOpts.url = url;
        AjaxSync(ajaxOpts, {
            done: (res)=> {
                if( res.status === 'success' ) {
                    this.isEditing = false;
                    this.rebuildTables();
                } else {
                    displayErrors(res);
                }
            }
        });
    }

    renderComments($table, content)
    {
        for (const date in content) {
            const count = content[date];
            const $list = $table.find('td#' + date + ' > span.icons');

            const $comment = $('<span class="icon">').addClass('Comment').attr('title', `${count} comment(s)`).html(tachoCalendarIcons.Comment.icon);

            $list.append($comment);
        }
    }

    displayVehicleActivityTab (e) {
        const date = this.getDateFromCellId(e);
        this.displayActivityTab('tco_veh_activity', app.URI[2], date)
    }

    displayDriverActivityTab (e) {
        const el = $(e.currentTarget);
        if(el.data('working-elsewhere')) {
            window.location = `${app.CACHE.URL_ADMIN}tco_drv_sup/${el.data('working-elsewhere')}`;
            return;
        }

        const date = this.getDateFromCellId(e);
        this.displayActivityTab('tco_drv_activity', app.URI[2], date)
    }

    getDateFromCellId (e) {

        let $cell;

        if( e.currentTarget ) {
            $cell = $(e.currentTarget);

            if (e.currentTarget.tagName != 'TD') {
                $cell = $cell.parents('td');
            }
        } else {
            $cell = e;
        }

        return $cell.attr('id').replace(/(\d{4})(\d{2})(\d{2})/, '$3-$2-$1');
    }

    setupContextMenus($table) {

        if( !$table.length ) {
            return;
        }

        let contextMenuOptions = {
            selector: 'td.date.has-tacho-data',
            items: {
                holiday: {
                    name: "Add/Delete Holiday",
                    callback: (type, opts) => this.ajaxAddDeleteEvent(type, opts.$trigger)
                },
                sick: {
                    name: "Add/Delete Sick Day",
                    callback: (type, opts) => this.ajaxAddDeleteEvent(type, opts.$trigger)
                },
                unpaid: {
                    name: "Add/Delete Unpaid/Rest Day",
                    callback: (type, opts) => this.ajaxAddDeleteEvent(type, opts.$trigger)
                },
                not_working_here: {
                    name: 'Add/Delete Working Elsewhere Day',
                    callback: (type, opts) => this.workingElsewhereAddDelete(type, opts.$trigger)
                },
                training: {
                    name: 'Add/Delete Training Day',
                    callback: (type, opts) => this.ajaxAddDeleteEvent(type, opts.$trigger, null, null, $table)
                }
            },
            events: {
                preShow: (_e) => {
                    if(this.isEditing) {
                        return false;
                    }
                }
            }
        };

        $table.contextMenu(contextMenuOptions);

        contextMenuOptions.selector = 'td.date.has-no-tacho-data';
        contextMenuOptions.items.seperator = '-';
        contextMenuOptions.items.editday = {
            name: 'Edit Day',
            callback: (type, opts) => {
                window.location = `${app.CACHE.URL_ADMIN}tco_drv_activity_edit_day/${app.URI[2]}/${this.getDateFromCellId(opts.$trigger)}`;
            }
        };

        $table.contextMenu(contextMenuOptions);
    }

    displayActivityTab(page, entity_id, date)
    {
        window.location = `${app.CACHE.URL_ADMIN}${page}/${entity_id}/${date}`;
    }

    calenderRendered($table) {
        $table.removeClass('loading');
    }
}